import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Tooltip } from "primereact/tooltip";
import PrimeReact from "primereact/api";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "../AppFooter";
import { AppMenu } from "../AppMenu";
import { AppConfig } from "../AppConfig";
import { UserContext } from "../context";
import { AuthRouter } from "../routes/ProtectedRoutes";

import Dashboard from "../pages/Dashboard";
import Categories from "../pages/Categories";
import Brands from "../pages/Brands";
import Types from "../pages/Types";
import Products from "../pages/Products";
import Customers from "../pages/Customers";
import AddCustomer from "../pages/customers/AddCustomer";
import Settings from "../pages/Settings";
import Login from "../pages/Login";
import Orders from "../pages/Orders";
import Proposals from "../pages/Proposals";
import PreviewProduct from "../pages/product/PreviewProduct";
import EditProduct from "../pages/product/EditProduct";
import AddProduct from "../pages/product/AddProduct";
import UpdateCustomer from "../pages/customers/UpdateCustomer";
import OrderDetail from "../pages/orders/OrderDetail";
import Notifications from "../pages/Notifications";
import Admins from "../pages/Admins";
import PageNotFound from "../pages/PageNotFound";
import Gifts from "../pages/Gifts";
import RedeemGifts from "../pages/RedeemGifts";
import Offers from "../pages/Offers";

import {
  menuSousAdmin,
  menuSuperAdmin,
  renderSideMenu,
} from "../utils/appMenu";
import { ProposalsService } from "../service/ProposalsService";
import { proposalCount } from "../atoms/Atom";
import { useRecoilValue } from "recoil";
import DeliveryPeople from "../pages/DeliveryPeople";
import Expeditions from "../pages/Expeditions";

const AppLayout = () => {
  const proposalService = new ProposalsService();

  const {
    userInfo: { isAuth },
  } = useContext(UserContext);
  const [layoutMode, setLayoutMode] = useState("static");
  const count = useRecoilValue(proposalCount); // if proposal count changes trigger useEffect
  const [waitAuth, setWaitAuth] = useState(false);
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [unreadProposals, setUnreadProposals] = useState(null); // count of unread proposals
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();
  const [user, setUser] = useState(null);

  console.log("count", count);

  useEffect(() => {
    const userInfos = localStorage.getItem("userInfo");
    if (userInfos) {
      setUser(JSON.parse(userInfos));
    }
  }, []);

  useEffect(() => {
    getUnreadProposals();
  }, [count]);

  // GET COUNT OF UNREAD PROPOSALS
  const getUnreadProposals = async () => {
    const res = await proposalService.getUnreadProposals();
    if (res.data) {
      if (res.data?.suggestions?.length > 0) {
        setUnreadProposals(res.data?.suggestions?.length);
      }
    } else {
      alert("erreur");
    }
  };

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  const waitingAuth = () => {
    setTimeout(() => {
      setWaitAuth(true);
    }, 1500);
  };

  useEffect(() => {
    waitingAuth();
  }, []);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  if (!waitAuth) {
    return (
      <div
        style={{ height: "100vh" }}
        className="flex align-items-center justify-content-center"
      >
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "3rem" }}></i>
      </div>
    );
  }

  return (
    <>
      {isAuth ? (
        <div className={wrapperClass} onClick={onWrapperClick}>
          <Tooltip
            ref={copyTooltipRef}
            target=".block-action-copy"
            position="bottom"
            content="Copied to clipboard"
            event="focus"
          />

          <AppTopbar
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
          />

          <div
            className="custom-layout-sidebar layout-sidebar"
            onClick={onSidebarClick}
          >
            <AppMenu
              model={
                user?.type === "super-admin"
                  ? renderSideMenu("superAdmin", unreadProposals)
                  : renderSideMenu("sousAdmin", unreadProposals)
              }
              onMenuItemClick={onMenuItemClick}
              layoutColorMode={layoutColorMode}
            />
          </div>

          <div className="layout-main-container custom-container-main-layout">
            <div className="layout-main">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/types" element={<Types />} />
                <Route path="/brands" element={<Brands />} />
                <Route path="/products" element={<Products />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/proposals" element={<Proposals />} />
                <Route path="/notifications" element={<Notifications />} />

                <Route path="/products/:_id" element={<PreviewProduct />} />
                <Route path="/products/edit/:_id" element={<EditProduct />} />
                <Route path="/products/add" element={<AddProduct />} />

                <Route path="/gifts" element={<Gifts />} />
                <Route path="/orderedgifts" element={<RedeemGifts />} />
          

                <Route path="/customers" element={<Customers />} />
                <Route path="/customers/add" element={<AddCustomer />} />
                <Route
                  path="/customers/edit/:_id"
                  element={<UpdateCustomer />}
                />

                <Route path="/orders" element={<Orders />} />
                <Route path="/orders/detail/:id" element={<OrderDetail />} />

                <Route path="/livreurs" element={<DeliveryPeople/>} />
                <Route path="/expeditions" element={<Expeditions/>} />

                {user?.type === "super-admin" && (
                  <Route path="/admin" element={<Admins />} />
                )}

                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>

            <AppFooter layoutColorMode={layoutColorMode} />
          </div>

          <AppConfig />

          <CSSTransition
            classNames="layout-mask"
            timeout={{ enter: 200, exit: 200 }}
            in={mobileMenuActive}
            unmountOnExit
          >
            <div className="layout-mask p-component-overlay"></div>
          </CSSTransition>
        </div>
      ) : (
        <Routes>
          <Route element={<AuthRouter isAuth={isAuth} />}>
            <Route path="/" element={<Login />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default AppLayout;
