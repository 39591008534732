import React,{ useEffect ,useRef,useState } from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { DeliveryPeopleService } from '../../service/DeliveryPeopleService';
import io from 'socket.io-client';


const socket = io("wss://api.cpo-app.com", { autoConnect: false });

const containerStyle = {
  width: '90vw',
  height: '100vh', // Set to full viewport height or any other value
};

const center = {
  lat: 34.008325729257756, 
  lng: -6.853770974342919
}

const RealTimeDeliveryMap = ({ rowData }) => {

  const deliveryPeopleService = new DeliveryPeopleService()
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const hideDialog = () => setDialogVisibility(false)
  const openNew = () => setDialogVisibility(true)
  const [map, setMap] = React.useState(null)
  const toast = React.useRef(null)
  const [deliveryManPosition, setDeliveryManPosition] = useState(null)
  const [socketData, setSocketData] = useState(null)
  const [loadinPosition, setLoadinPosition] = useState(false)
  const [lastPosition, setLastPosition] = useState(null)
  const markerRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAOSDEF3tpixNJIbh5IpDPUaZXiuJJQGqg',
  })
  
  useEffect(() => {
    if (dialogVisibility) {
      getLastPosition()

      socket.connect();
      socket.on('connect', () => {
        console.log('Socket connected:', socket.connected);
        console.log(`location_updated:${rowData._id}`)
        socket.on(`location_updated:${rowData._id}`, (data) => {
          console.log("data received:", data);
          if(data?.location){
            setSocketData(data)
            deliveryPeopleService.createLocationHistory({
              deliveryMan: rowData._id,
              coordinates: {
                latitude: data.location.latitude,
                longitude: data.location.longitude,
                angle: data.location.heading
              }
            })
          }
          if(deliveryManPosition){
            animateMarker({
              lat: data.location.latitude,
              lng: data.location.longitude,
            });
          } else {
            //Animate map
            setDeliveryManPosition({
              lat: data.location.latitude,
              lng: data.location.longitude,
            })

          }

        });
      });
      socket.on('connect_error', (error) => {
        console.error('Connection error:', error);
      });
    }
  
    // Clean up to avoid multiple listeners
    return () => {
      socket.off(`location_updated:${rowData._id}`);
      socket.disconnect();
    };
  }, [dialogVisibility, rowData._id]);

  const getLastPosition = async () => {
    setLoadinPosition(true)
    const response = await deliveryPeopleService.getLastPosition(rowData._id);
    if(response.data){
      console.log(response.data)
      setLastPosition({
        lat: response.data.coordinates.latitude,
        lng: response.data.coordinates.longitude,
      })
    } else {
      console.log(response.error);
    }
    setLoadinPosition(false);
  }

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center)
    // map.fitBounds(bounds)
    // setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const animateMarker = (newPosition) => {
    const start = deliveryManPosition;
    const end = newPosition;
    const duration = 1000; // Duration of the animation in ms
    let startTime;
  
    const animateStep = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = (timestamp - startTime) / duration;
  
      // Interpolate latitude and longitude for smooth transition
      const lat = start.lat + (end.lat - start.lat) * progress;
      const lng = start.lng + (end.lng - start.lng) * progress;
  
      setDeliveryManPosition({ lat, lng });
  
      if (progress < 1) {
        requestAnimationFrame(animateStep); // Continue animation
      } else {
        setDeliveryManPosition(end); // Snap to final position
      }
    };
  
    requestAnimationFrame(animateStep);
  };

  const generateHeaderTile = () => {
    let text = `Livreur: ${rowData.fullName}`
    if(socketData?.customerName){
      text+= `, En route vers le client:${socketData.customerName}`
    }
    return text
  }

  return (
    <>
      <Toast ref={toast} />
      <Button
        icon="pi pi-map-marker" 
        className="w-auto p-button-sm p-button-text p-button-secondary" 
        onClick={openNew}
        style={{ color: "green" }}
      />

        {/* MODAL */}
        <Dialog 
          draggable={false} 
          visible={dialogVisibility} 
          maximizable
          //breakpoints={{ '960px': '100vw', '641px': '100vw' }}
          header={generateHeaderTile()} modal 
          className="p-fluid" onHide={hideDialog}
        >
        {loadinPosition ?
          <div className="card">
            <ProgressSpinner 
              style={{width: '50px', height: '50px'}} 
              strokeWidth="8" 
              fill="var(--surface-ground)" 
              animationDuration=".5s" 
            />
          </div> :
          <>
          {(deliveryManPosition || lastPosition) &&
          <>
          {isLoaded &&   
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={deliveryManPosition || lastPosition}
              zoom={15}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {(deliveryManPosition || lastPosition) &&
                <Marker 
                  options={{
                    icon:{
                      url:'https://firebasestorage.googleapis.com/v0/b/cpopara.appspot.com/o/delivery-bike.png?alt=media&token=07f122bf-d2e5-4c41-b45b-27774598cf41',
                      scaledSize: new window.google.maps.Size(40, 40),
                      rotation: socketData?.location?.heading
                    }
                  }}
                  position={deliveryManPosition || lastPosition}
                />
              }
            </GoogleMap>
          } 
          </>         
          }

          </>
        }

      </Dialog>
    </>
  )
}

export default RealTimeDeliveryMap
