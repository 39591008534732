import Api from "../utils/Api";

export class ExpeditionsService {

  getAll = async (queryParams) => {
    let response = {}
    try {
      console.log("query params: ",queryParams)
      const res = await Api.get(`admin/expeditions?${queryParams}`)
      response.data = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }
  
}