import React, { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import NoData from "../NoData";
const ExpeditionDetails = ({rowData, dialogVisibility, setDialogVisibilty}) => {
  console.log(rowData)
  const dt = useRef(null);

  return (
    <Dialog 
      draggable={false} 
      visible={dialogVisibility}
      breakpoints={{'1900px': '60vw', '640px': '100vw'}}
      header="Détails de l'expédition" modal 
      className="p-fluid" 
      onHide={() => setDialogVisibilty(false)}
    >
          <DataTable
            rows={20}
            filterDisplay="row"
            stripedRows
            ref={dt}
            rowHover
            responsiveLayout="scroll"
            value={rowData.order_ids}
            size="small"
            dataKey="_id"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} à {last} de {totalRecords}"
            emptyMessage={
            <NoData label="Aucun commande disponible" />
            }
          >
            <Column
              field="refBc"
              header="BC"
            />
            <Column 
              field="customerName"
              header="Client"
            />
            <Column 
              field="total"
              header="Total"
            />
            <Column 
              field="status"
              header="Status"
            />

          </DataTable>
    </Dialog>
  )
}

export default ExpeditionDetails;